import React from "react";
import {
  Button,
  Form,
  FormControl,
  FormLabel,
  InputGroup,
} from "react-bootstrap";
import Recaptcha from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import Select, { components } from "react-select";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
export default function InfluencerRegster() {
  const recaptchaRef = React.createRef();
  const [value, setvalue] = React.useState('')

  const handleOnchange = val => {
    setvalue(val)
  }

  const optionss = [
    { label: 'Fashion', value: 'Fashion' },
    { label: 'Fitness', value: 'Fitness' },
    { label: 'Foodie', value: 'Foodie' },
    { label: 'Lifestyle', value: 'Lifestyle' },
    { label: 'Model', value: 'Model' },
    { label: 'Mommy Blogger', value: 'Mommy_Blogger' },
    { label: 'Nightlife', value: 'Nightlife' },
    { label: 'Other ', value: 'Other' },
  ]
  const [formState, setFormState] = React.useState({
    FullName: "",
    InfluencerType: "",
    URL: "",
    Email: "",
    Phone: "",
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  function GetValueByID(str) {
    var a = document.getElementById(str);
    if (a) return a.value;
    else {
      return "";
    }
  }

  const handleSubmit = (e) => {
    let x = GetValueByID("FullName");
    let n = GetValueByID("URL");
    let y = GetValueByID("Email");
    let p = GetValueByID("Phone");
    let k = document.querySelector(".msl-input-wrp ").innerText.replace("\n", ",");
    let phoneCode = document.querySelector(".react-tel-input input")?.value;
    let ChannelName = document.querySelector(".react-select__value-container .img-icons img").alt;
    if (ChannelName === "Facebook_logo") ChannelName = "https://www.facebook.com/" + n;
    if (ChannelName === "Instagram_logo") ChannelName = "https://www.instagram.com/" + n;
    if (ChannelName === "Youtube_logo") ChannelName = "https://www.youtube.com/" + n;

    const obj = {
      FullName: x,
      URL: ChannelName,
      Email: y,
      InfluencerType: k,
      Phone: phoneCode + p,
    };
    const recaptchaValue = recaptchaRef.current.getValue();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "Influencer-contact",
        ...obj,
        "g-recaptcha-response": recaptchaValue,
      }),
    })
      .then(() => alert("Thank you"))
      .catch((error) => alert(error));

    e.preventDefault();
  };
  const options = [
    { value: "facebook", label: "", icon: "Facebook_logo" },
    { value: "instagram", label: "", icon: "Instagram_logo" },
    { value: "youtube", label: "", icon: "Youtube_logo" },
  ];
  const { Option } = components;
  const CustomSelectOption = (props) => (
    <Option {...props} className="option-icons">
      <img
        src={`../img/${props.data.icon}.svg`}
        width="20"
        height="20"
        alt={`${props.data.value}`}
      />
      {props.data.label}
    </Option>
  );
  const CustomSelectValue = (props) => (
    <div className={`img-icons`}>
      <img
        src={`../img/${props.data.icon}.svg`}
        width="20"
        height="20"
        alt={`${props.data.icon}`}
      />
      {props.data.label}
    </div>
  );

  return (
    <>
      <form
        onSubmit={handleSubmit}
        name="Influencer-contact"
        method="post"
        data-netlify-recaptcha="true"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        autocomplete="off"
        id="address-form"
        className="text-start mt-3 select-icons"
        action="/"
      >
        <input type="hidden" name="form-name" value="Influencer-contact" />

        <Form.Group className="mb-1" controlId="formBasicName">
          <Form.Label className="fs-13 bogo-par">Type of Influencer Select all that apply:</Form.Label> <br />
          <MultiSelect style={{ width: '100%' }} name="InfluencerType" id="InfluencerType"
            onChange={handleOnchange}
            options={optionss}
          />
        </Form.Group>
        <Form.Group className="mb-1" controlId="formBasicName">
          <Form.Label className="fs-13 bogo-par">Full Name</Form.Label>
          <Form.Control type="text" name="FullName" id="FullName" required />
        </Form.Group>
        <Form.Group className="mb-1" controlId="formBasicEmail">
          <Form.Label className="fs-13 bogo-par">Email address</Form.Label>
          <Form.Control type="email" name="Email" id="Email" />
        </Form.Group>
        <FormLabel className="fs-13 bogo-par">Social media</FormLabel>
        <InputGroup className="mb-1">
          <>
            <Select
              className="span-icons"
              classNamePrefix="react-select"
              options={options}
              components={{
                Option: CustomSelectOption,
                SingleValue: CustomSelectValue,
              }}
            />
          </>
          <FormControl
            aria-label="Text input with dropdown button"
            placeholder="Your Handle or URL"
            name="URL"
            id="URL"
            required
          />
        </InputGroup>
        <FormLabel className="fs-13 bogo-par">Mobile number</FormLabel>
        <InputGroup className="mb-2">
          <PhoneInput country="ae" enableAreaCodes={true} />

          <FormControl
            type="tel"
            aria-label="Text input with dropdown button"
            name="Phone"
            id="Phone"
          />
        </InputGroup>
        <Recaptcha
          ref={recaptchaRef}
          sitekey="6LeUanMcAAAAAAk3U8ieboKe0uqx1LNFb8i4QvUi"
          size="normal"
          id="recaptcha-google"
        />

        <Button
          variant="primary"
          className="w-100 fs-14 fw-bold h-52"
          type="submit"
        >
          Submit
        </Button>
      </form>
    </>
  );
}
