import { Link } from "gatsby";
import * as React from "react";
import Recaptcha from "react-google-recaptcha";
import Select, { components } from "react-select";
import {
  Button,
  Container,
  Form,
  FormControl,
  FormLabel,
  InputGroup,
  Row,
} from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import Layout from "../components/Layout";
import { graphql } from "gatsby";

import InfluencerRegster from "../components/influencerregister"
export default function InfluencerPage({ data }) {
  const [show_Form, setShowForm] = React.useState(true)
  const recaptchaRef = React.createRef();

  const [formState, setFormState] = React.useState({
    firstname: "",
    email: "",


  })
  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }
  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value

    })
  }
  const handleSubmit = e => {
    const recaptchaValue = recaptchaRef.current.getValue()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "Contact-client", 'g-recaptcha-response': recaptchaValue, ...formState })
    })
      .then(() => alert("Thank you"))
      .catch(error => alert(error));

    e.preventDefault();
  }
  const { Option } = components;
  const CustomSelectOption = (props) => (
    <Option {...props} className="option-icons">
      <img src={`../img/${props.data.icon}.svg`} width="20" height="20" alt={`${props.data.icon}`} />
      {props.data.label}
    </Option>
  );
  const CustomSelectValue = (props) => (
    <div className={`img-icons`}>
      <img src={`../img/${props.data.icon}.svg`} width="20" height="20" alt={`${props.data.icon}`} />
      {props.data.label}
    </div>
  );

  const options = [
    { value: "facebook", label: "", icon: "Facebook_logo" },
    { value: "instagram", label: "", icon: "Instagram_logo" },
    { value: "youtube", label: "", icon: "Youtube_logo" },
  ];
  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  const Merchant = data.Merchant.nodes;
  const Options = data.Options.nodes;
  const ImageWidget = data.ImageWidget.nodes;
  const BogoBrochure = data.BogoBrochure.nodes;

  return (
    <Layout>
      <main>
        <Container>
          <section>
            <div className="col-xl-12 col-lg-12 col-md-12 pb-5 pt-3 px-4">
              <Row>
                {Options.map((node, index) => {
                  return (
                    <>
                      <div className="col-xl-6 col-lg-6 col-md-12 pe-xl-5">
                        <h2 className="fw-bold mb-4">
                          {node.BrandOptionsTitle}
                        </h2>
                        <h5 className="bogo-par fw-normal lh-28 mb-4 pe-xl-5 fs-18">
                          {node.TextWidgetwithoptionsDescription}
                        </h5>
                        <p className="primary-color fw-bold mb-1">
                          {node.BrandOptionsTitle}
                        </p>
                        {node?.BrandOptions?.length > 0 &&
                          node.BrandOptions.map((node2) => (
                            <p className="bogo-par fs-18 mb-1">
                              <span className="primary-color fw-bold">✓</span>{" "}
                              {node2.BrandOptionsItem}
                            </p>
                          ))}
                      </div>
                    </>
                  );
                })}

                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="d-flex">
                    <img src="../img/influencer-register.svg" alt="register" />
                    <h5 className="fw-bold fs-26 mt-4 ms-4">
                      Submit your interest
                    </h5>
                  </div>
                  <div id="registration">
                    <InfluencerRegster />
                  </div>
                </div>
              </Row>
            </div>
          </section>
          <section>
            <div className="col-xl-12 col-lg-12 col-md-12 py-5 px-4 my-4">
              <Row className="bg-light">
                {ImageWidget.map((node, index) => {
                  return (
                    <>
                      <div className="col-xl-5 col-lg-6 col-md-12 ps-0">
                        <img
                          src={node.BrandImage}
                          className="become-bogo"
                          alt="Influencer"
                        />
                      </div>
                      <div className="col-xl-6 col-lg-5 col-md-12 py-4 px-xl-5 ms-xl-5 ms-lg-5 mt-2 become-bogo">
                        <h5 className="fw-bold mb-4">{node.Branditle}</h5>
                        <p className="bogo-par fs-18 mb-1">
                          {node.BrandDescription}
                        </p>
                        <div className="Link-become pt-4">
                          <Link
                            to={node.BrandLink}
                            className="fs-18 link-color pb-2"
                            onClick={() => {
                              try {
                                document.querySelector("#FullName").focus();
                              } catch (err) { }
                            }}
                          >
                            {node.BrandText}{" "}
                            <img
                              src="../img/arrow-right.svg"
                              alt="arrow right"
                              className="ms-3"
                            />
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Row>
            </div>
          </section>
        </Container>

        <Container fluid={true} className="business-h bg-light mt-4 mb-xl-1 mb-lg-1 mb-md-1 mb-5">
          <section>
            <Container className="me-0">
              <Row>
                {BogoBrochure.map((node, index) => {
                  return (
                    <>
                      <div className="col-xl-7 col-lg-5 col-md-12 py-5">
                        <h2 className="fx-bold mb-2">{node.BrochureTitle}</h2>
                        <h4 className="fs-bold primary-color fs-22">
                          {node.BrochureDescription}
                        </h4>
                        <form
                          onSubmit={handleSubmit}
                          name="Contact-client"
                          method="post"
                          data-netlify-recaptcha="true"
                          data-netlify="true"
                          data-netlify-honeypot="bot-field"

                        >
                          <input type="hidden" name="form-name" value="Contact-client" />


                          <Form.Group
                            className="mb-2 col-xl-6 col-lg-6 col-md-8 col-12"
                            controlId="exampleForm.ControlInput2"
                          >
                            <Form.Control
                              type="text"
                              style={{
                                "margin-top": "0.9rem",
                                background: "white",
                              }}

                              placeholder="Full Name"
                              id="firstname"
                              type="text"
                              name="firstname"
                              onChange={handleChange}
                              value={formState.firstname}
                              required

                            />
                            <Form.Control
                              type="email"
                              style={{
                                "margin-top": "1rem",
                                background: "white",
                              }}
                              placeholder="Email"
                              id="email"
                              name="email"
                              onChange={handleChange}
                              value={formState.email}

                              required
                            />


                          </Form.Group>

                          <Form.Group
                            className="mb-2"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Recaptcha
                              ref={recaptchaRef}
                              sitekey="6LeUanMcAAAAAAk3U8ieboKe0uqx1LNFb8i4QvUi"
                              size="normal"
                              id="recaptcha-google"
                              onChange={() => setButtonDisabled(false)}
                            />
                          </Form.Group>
                          <Button
                            className="w-30 fs-14 fw-bold btn btn-primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </form>
                        {/* <div className="Link-become pt-5">
                          <Link to={node.BrochureCTALink} className="fs-18 link-color pb-2">
                            {node.BrochureCTAText}{" "}
                            <img
                              src="../img/arrow-right.svg"
                              alt="arrow right"
                              className="ms-3"
                            />
                          </Link>
                        </div>*/}
                      </div>
                      <div className="col-xl-5 col-lg-7 col-md-12 pe-0 ms-auto vector wb-right d-none d-sm-block">
                        <div className="row text-right">
                          <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                            <img
                              src={node.BrochureImage}
                              alt="Iphone"
                              height="578"
                              className="nav-link pe-0"
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                            <img
                              src={node.SecondBrochureImage}
                              alt="Iphone"
                              height="578"
                              className="nav-link pe-0"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-5 col-lg-7 col-md-12 pe-0 ms-auto vector wb-right d-block d-sm-none">
                        <div className="d-flex text-right">
                          <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                            <img
                              src={node.BrochureImage}
                              alt="Iphone"
                              className="nav-link pe-0 become-bogo"
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                            <img
                              src={node.SecondBrochureImage}
                              alt="Iphone"
                              className="nav-link pe-0 become-bogo"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Row>
            </Container>
          </section>
        </Container>
        <Container fluid={true} className="py-xl-5 px-lg-3  pt-5">
          <section className="Light-bg my-xl-5 my-lg-5">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <Row>
                {Merchant.map((node, index) => {
                  return (
                    <>
                      <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-12 ps-0 bg-last-section">
                        <img src={node.MerchantImage} alt="bg" />
                      </div>
                      <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 ps-lg-0 pt-xl-5 pt-lg-5 pb-sm-5 ps-sm-5 pt-sm-5 ps-xxl-0 ps-xl-5">
                        <h4 className="fs-40 fw-bold mb-5">
                          {node.MerchantTitle}
                        </h4>
                        <h5 className="bogo-par fw-normal lh-32 mb-5 fw-airlight pe-xl-5 pe-lg-5 pe-md-5 pe-0 me-xl-5 me-lg-5 me-md-5 me-0">
                          {node.MerchantDescription}
                        </h5>
                        <Link
                          to={node.MerchantCTALink}
                          className="fs-18 link-color pb-2"
                        >
                          {node.MerchantCTAText}{" "}
                          <img
                            src="../img/arrow-right.svg"
                            alt="arrow right"
                            className="ms-3"
                          />
                        </Link>
                      </div>
                    </>
                  );
                })}
              </Row>
            </div>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
export const query = graphql`
  query {
    Merchant: allHomeJson(filter: { MerchantTitle: { ne: null } }) {
      nodes {
        MerchantCTALink
        MerchantCTAText
        MerchantDescription
        MerchantImage
        MerchantTitle
      }
    }
    Options: allBrandJson(
      filter: { TextWidgetwithoptionsDescription: { ne: null } }
    ) {
      nodes {
        BrandOptions {
          BrandOptionsItem
        }
        TextWidgetwithoptionsDescription
        TextWidgetwithoptionsTitle
        BrandOptionsTitle
      }
    }
    ImageWidget: allBrandJson(filter: { Branditle: { ne: null } }) {
      nodes {
        BrandDescription
        BrandImage
        BrandLink
        BrandOptionsTitle
        BrandText
        Branditle
      }
    }
    BogoBrochure: allResourceJson(filter: { BrochureTitle: { ne: null } }) {
      nodes {
        BrochureCTALink
        BrochureCTAText
        BrochureDescription
        BrochureImage
        SecondBrochureImage
        BrochureTitle
      }
    }
  }
`;